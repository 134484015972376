<template>
  <v-dialog
    :value="value"
    max-width="650"
    persistent
  >
    <v-card class="user-card-detail pa-sm-10 pa-3">
      <v-row>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-row>
      <v-card-title class="user-edit-title justify-center text-h5">
        {{ option.title }}
      </v-card-title>
      <v-card-text class="text-center mt-n2" v-if="option.delete">
        {{ "Por favor seleccione el tipo de item a borrar" }}
      </v-card-text>
      <v-card-text class="text-center mt-n2" v-else>
        {{ option.create ? 'Crear Nuevo Item' : 'Por favor seleccione el tipo de item a editar'}}
      </v-card-text>
      <v-card-text class="text-center mt-n2 subtitle-1" v-if="!select">
        {{option.create ? 'NUEVA ' : option.delete ? 'BORRAR ' : 'EDITAR '}} {{ line ? 'LÍNEA' : 'CATEGORÍA' }}
      </v-card-text>
      <v-alert
        v-model="alert"
        type="error"
        class="mb-0"
        dense
      >
        {{ text }}
      </v-alert>
      <div class="d-flex justify-space-around" v-if="select">
        <v-btn
          large
          @click="setMode(0)"
        >
          {{option.create ? 'NUEVA CATEGORÍA' : option.delete ? 'BORRAR CATEGORÍA' : 'EDITAR CATEGORIA'}}
        </v-btn>
        <v-btn
          large
          @click="setMode(1)"
        >
          {{option.create ? 'NUEVA LÍNEA' : option.delete ? 'BORRAR LÍNEA' : 'EDITAR LÍNEA'}}
        </v-btn>
      </div>
      <div v-else>
        <v-col
          v-if="!option.create || option.delete"
          cols="12"
        >
          <v-autocomplete
            v-model="editItem"
            :items="line ? option.selectList : option.selectList2"
            :label="option.delete ? 'Item a borrar' : 'Item a editar'"
            :loading="loadingOne"
            hide-details
            outlined
          ></v-autocomplete>
        </v-col>

        <v-card-text class="mt-5" v-if="(editItem && !loadingOne) || option.create">
          <v-form
            class="multi-col-validation"
            @submit.prevent="submit"
            ref="form"
          >
            <v-row v-if="!option.delete && option.model !== 'third_parties'" class="justify-center">
              <v-col
                cols="12"
                md="6"
                v-if="line"
              >
                <v-text-field
                  v-model.trim="item.code"
                  label="Código"
                  @change="verifyCode"
                  :autofocus="option.create"
                  outlined
                  dense
                  :rules="[validators.required]"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model.trim="item.name_of"
                  @change="verifyName"
                  outlined
                  dense
                  :label="line ? 'Nombre Línea' : 'Nombre Categoría'"
                  :rules="[validators.required]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                v-if="line"
              >
                <v-select
                  v-model.trim="item.line_category_id"
                  :items="option.selectList2"
                  outlined
                  dense
                  label="Categoría"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                class="d-flex justify-center"
              >
                <v-btn
                  color="primary"
                  class="me-3"
                  type="submit"
                  :loading="loading"
                >
                  {{option.delete ? 'Eliminar' : 'Guardar'}}
                </v-btn>
                <v-btn
                  color="secondary"
                  outlined
                  @click="close"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { ref, reactive, inject, watch } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { required, emailValidator } from '@core/utils/validation'
import { validateForm } from '@/@core/utils'
export default {
  props: {
    value: {
      default: false,
      type: Boolean,
      required: true,
    },
    options: {
      default: () => {},
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const alert = ref(false)
    const text = ref('')
    const editItem = ref('')
    const line = ref(false)
    const loading = ref(false)
    const select = ref(true)
    const loadingOne = ref(false)
    const form = ref(null)
    const option = props.options
    const item = reactive({
      stated_at: option.delete ? false : true,
    })
    const create = inject('createItem')
    const update = inject('updateItem')
    const setOne = inject('setOneItem')

    watch(editItem, val => {
      getOne(val)
    })

    const close = () => {
      emit('input', false)
      if (form.value) form.value.reset()
      editItem.value = ''
      select.value = true
      alert.value = false
    }

    const submit = () => {
      const isFormValid = validateForm(form)
      if (!isFormValid) return
      if (alert.value) return
      let params = {}
      const keyName = line.value ? option.keyName : option.keyName2
      if (option.delete) item.stated_at = false
      params[keyName] = item
      let payload = {
        model: line.value ? option.model : option.model2,
        params,
      }
      loading.value = true
      if (option.create) {
        create(payload)
          .then(res => {
            loading.value = false
            Vue.nextTick(() => close())
          })
          .catch(() => (loading.value = false))
      } else {
        const id = editItem.value
        payload.id = id
        update(payload).then(res => {
          loading.value = false
          Vue.nextTick(() => close())
        })
      }
    }

    const getOne = val => {
      const id = val
      let payload = {
        model: line.value ? option.model : option.model2,
        id,
      }
      loadingOne.value = true
      setOne(payload)
        .then(res => {
          Object.assign(item, res)
          if (line.value) item.line_category_id = res.line_category.id
          loadingOne.value = false
        })
        .catch(() => (loadingOne.value = false))
    }

    const setMode = val => {
      if (val === 0) {
        line.value = false
      } else line.value = true
      select.value = false
    }

    const verifyCode = val => {
      text.value = `Ya existe un item con ${val} de código`
      let exist = option.selectList.find(el => el.code === val)
      if (exist) {
        alert.value = true
        option.create = false
        editItem.value = exist.value
      } else alert.value = false
    }

    const verifyName = val => {
      text.value = `Ya existe un item con ${val} de nombre`
      const value = line.value ? option.selectList : option.selectList2
      let exist = value.find(item => {
        if (item.text && val) {
          return item.text.toLowerCase() === val.toLowerCase()
        } else return false
      })
      if (exist) {
        alert.value = true
        option.create = false
        editItem.value = exist.value
      } else alert.value = false
    }

    return {
      alert,
      close,
      editItem,
      form,
      getOne,
      line,
      loading,
      loadingOne,
      item,
      option,
      setMode,
      select,
      submit,
      text,
      verifyCode,
      verifyName,
      icons: {
        mdiClose,
      },
      validators: {
        emailValidator,
        required,
      },
    }
  },
}
</script>

