<template>
  <v-dialog
    :value="value"
    max-width="450"
    persistent
  >
    <v-card class="pa-3">
      <v-card-text class="headline text-center">
        {{text}}
      </v-card-text>
      <v-card-text class="title text-center">
        {{text2}}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="$emit('input', false)"
        >
          Retornar
        </v-btn>
        <v-btn
          color="success"
          :loading="loading"
          @click="$emit('confirm')"
        >
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'
export default {
  name: 'DialogConfirm',
  props: {
    value: {
      default: false,
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    text2: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    return {
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
