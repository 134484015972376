<template>
  <div>
    <v-card>
      <v-app-bar dark color="primary" flat dense>
        <v-app-bar-title class="white--text ml-3">{{Object.keys($route.params).length === 0 ? 'Nuevo Proyecto' : 'Editar Proyecto' }}</v-app-bar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon color="white" >{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-app-bar>
        <v-tabs
          v-model="currentTab"
          grow
        >
          <v-tab
            v-for="item in items"
            :key="item.title"
            :disabled="item.disabled"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ item.icon }}
            </v-icon>
            {{ item.title }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentTab">
          <v-tab-item :key="0" disabled>
            <v-card-text>
              <project-tab-ideation></project-tab-ideation>
            </v-card-text>
          </v-tab-item>
          <v-tab-item :key="1" disabled>
            <v-card flat>
              <v-card-text>
                <project-tab-formulation></project-tab-formulation>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item :key="2">
            <v-card flat>
              <v-card-text>{{ tabItemText }}</v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { ref, provide } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { mdiClose, mdiFolderEdit, mdiFolderClock, mdiFolderUpload } from '@mdi/js'
import ProjectTabIdeation from './ProjectTabIdeation'
import ProjectTabFormulation from './ProjectTabFormulation'
import useProjectsNew from './useProjectsNew'

export default {
  components: {
    ProjectTabIdeation,
    ProjectTabFormulation,
  },
  setup() {
    const currentTab = ref(null)
    const { router } = useRouter()
    const {
      budgetColumns,
      costColumns,
      configModal,
      create,
      createProject,
      createProjectFormulations,
      editorOption,
      selectEmails,
      fetchOne,
      fetchOneFormulations,
      fetchSelectLists,
      loading,
      selectBudgetSourceTypesList,
      selectBudgetTypesList,
      selectGeographiesList,
      selectLinesList,
      selectLinesCategoriesList,
      selectOriginsList,
      selectPopulationsList,
      selectSectorsList,
      selectStatusList,
      selectSubSectorsList,
      selectThirdPartiesList,
      selectTypeList,
      selectUMList,
      selectZonesList,
      update,
      setOne,
      updateProject,
      updateProjectFormulations,
      userList,
    } = useProjectsNew()
    provide('budgetColumns', budgetColumns)
    provide('costColumns', costColumns)
    provide('userList', userList)
    provide('configModal', configModal)
    provide('create', create)
    provide('createProject', createProject)
    provide('createProjectFormulations', createProjectFormulations)
    provide('editorOption', editorOption)
    provide('fetchOne', fetchOne)
    provide('fetchOneFormulations', fetchOneFormulations)
    provide('setOne', setOne)
    provide('loading', loading)
    provide('update', update)
    provide('updateProject', updateProject)
    provide('updateProjectFormulations', updateProjectFormulations)
    const selects = {
      selectEmails,
      selectBudgetSourceTypesList,
      selectBudgetTypesList,
      selectGeographiesList,
      selectLinesList,
      selectLinesCategoriesList,
      selectOriginsList,
      selectPopulationsList,
      selectSectorsList,
      selectStatusList,
      selectSubSectorsList,
      selectThirdPartiesList,
      selectTypeList,
      selectUMList,
      selectZonesList,
    }
    provide('selects', selects)

    if (Object.keys(router.currentRoute.params).length !== 0) {
      if (router.currentRoute.params.status === 'formulation') {
        currentTab.value = 1
      }
    }

    loading.value = true
    Promise.all([fetchSelectLists()])
      .then(() => {
        loading.value = false
      })
      .catch(e => (loading.value = false))

    const items = [
      {
        title: 'IDEACIÓN',
        icon: mdiFolderEdit,
      },
      {
        title: 'FORMULACIÓN',
        icon: mdiFolderClock,
        disabled: !currentTab.value,
      },
      {
        title: 'EJECUCIÓN',
        icon: mdiFolderUpload,
      },
    ]
    const tabItemText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
      `
    const close = () => {
      router.push('/proyectos/lista')
    }
    return {
      close,
      currentTab,
      items,
      loading,
      tabItemText,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
