<template>
<div>
  <v-form
    ref="form"
  >
    <v-row class="pt-3">
      <v-col
        cols="12"
        md="3"
      >
        <v-text-field
          v-model="project.code"
          label="Código"
          hide-details="auto"
          :rules="[validators.required]"
          readonly
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="date_entry"
              label="Fecha De Ingreso"
              :prepend-icon="icons.mdiCalendar"
              hide-details="auto"
              readonly
              v-on="on"
              :rules="[validators.required]"
            />
          </template>
          <v-date-picker
            v-model="project.date_entry"
            @input="menu = false"
            :first-day-of-week="1"
            locale="es-ES"
          />
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-textarea
          v-model="project.description"
          label="Descripción"
          hide-details="auto"
          rows="1"
          :rules="[validators.required]"
        ></v-textarea>
      </v-col>
      <label class="ma-0 pt-1">Objetivo General y/o alcance</label>
      <v-col
        cols="12"
        class="pt-2"
      >
        <quill-editor
          v-model="project.objective"
          :options="editorOption"
          @change="onEditorChange($event)"
        ></quill-editor>
      </v-col>
      <label class="ma-0 pt-1">Objetivos Específicos</label>
      <v-col
        cols="12"
        class="pt-2"
      >
        <quill-editor
          v-model="project.specific_objectives"
          :options="editorOption"
          @change="onEditorChange($event)"
        ></quill-editor>
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <v-autocomplete
          v-model="project.user_id"
          :items="userList.filter(ele => ele.stated_at).filter(item => item.roles.some( ele => {
            return ele === 'project_manager'
          }))"
          label="Lider del Proyecto"
          item-text="fullname"
          hide-details
          :menu-props="{contentClass:'list-style'}"
          chips
          hide-details="auto"
        >
          <template #selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="project.user_id = ''"
            >
              <v-avatar
                left
                :color="data.item.avatar ? '' : 'primary'"
                :class="data.item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              >
                <v-img v-if="data.item.avatar" :src="`${data.item.avatar}`"></v-img>
                <span
                  v-else
                  class="font-weight-medium"
                >{{ avatarText(data.item.fullname) }}</span>
              </v-avatar>
              {{ data.item.fullname }}
            </v-chip>
          </template>
          <template #item="data">
            <v-list-item-avatar
              :color="data.item.avatar ? '' : 'primary'"
              :class="data.item.avatar ? '' : 'v-avatar-light-bg primary--text'"
            >
              <v-img
                v-if="data.item.avatar"
                :src="`${data.item.avatar}`"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(data.item.fullname) }}</span>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title >{{data.item.fullname}}</v-list-item-title>
              <v-list-item-subtitle><small>{{data.item.email}}</small></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-text-field
          v-model="project.duration"
          label="Duración"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-autocomplete
          v-model="project.unit_measure_duration"
          label="U. Duración"
          :items="measuresDuration"
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="12"
      >
        <v-autocomplete
          v-model="project.resource_managers"
          :items="userList.filter(ele => ele.stated_at).filter(item => item.roles.some( ele => {
            return ele === 'resource_manager'
          }))"
          label="Formuladores del Proyecto"
          item-text="fullname"
          hide-details
          hide-selected
          :menu-props="{contentClass:'list-style'}"
          chips
          multiple
          :rules="[validators.required]"
          hide-details="auto"
          :search-input.sync="inputSearch.resource_managers"
          @change="cleanSearch('resource_managers')"
        >
          <template #selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="remove(data.item, 'resource_managers')"
              class="chip-font"
            >
              <v-avatar
                left
                :color="data.item.avatar ? '' : 'primary'"
                :class="data.item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              >
                <v-img v-if="data.item.avatar" :src="`${data.item.avatar}`"></v-img>
                <span
                  v-else
                  class="font-weight-medium"
                >{{ avatarText(data.item.fullname) }}</span>
              </v-avatar>
              {{ data.item.fullname }}
            </v-chip>
          </template>
          <template #item="data">
            <v-list-item-avatar
              :color="data.item.avatar ? '' : 'primary'"
              :class="data.item.avatar ? '' : 'v-avatar-light-bg primary--text'"
            >
              <v-img
                v-if="data.item.avatar"
                :src="`${data.item.avatar}`"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(data.item.fullname) }}</span>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title >{{data.item.fullname}}</v-list-item-title>
              <v-list-item-subtitle><small>{{data.item.email}}</small></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-autocomplete
          v-model="project.project_type_id"
          :items="selectTypeList"
          label="Tipo"
          hide-details
        >
          <template v-slot:append-outer>
            <v-btn
              icon
              small
              color="primary"
              @click="showList($event, 'project_types')"
            >
              <v-icon color="primary">{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-autocomplete
          v-model="project.origin_id"
          :items="selectOriginsList"
          label="Rol CCB"
          hide-details
        >
          <template v-slot:append-outer>
            <v-btn
              icon
              small
              color="primary"
              @click="showList($event, 'origins')"
            >
              <v-icon color="primary">{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-autocomplete
          v-model="project.population_ids"
          multiple
          small-chips
          :items="selectPopulationsList"
          label="Beneficiarios"
          hide-details
          :search-input.sync="inputSearch.population_ids"
          @change="cleanSearch('population_ids')"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              color="primary"
              close
              @click="data.select"
              @click:close="remove(data.item, 'population_ids')"
              class="chip-font"
            >
            {{data.item.text}}
            </v-chip>
          </template>
          <template v-slot:append-outer>
            <v-btn
              icon
              small
              color="primary"
              @click="showList($event, 'populations')"
            >
              <v-icon color="primary">{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-autocomplete
          v-model="project.executors"
          multiple
          small-chips
          :items="selectZonesList"
          label="Vicepresidencia ejecutora"
          hide-details
          :search-input.sync="inputSearch.executors"
          @change="cleanSearch('executors')"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              :color="!getStated('exe',  data.item.value) ? 'primary' : '#BDBDBD'"
              :close-icon="!getStated('exe',  data.item.value) ? icons.mdiCloseCircle : ''"
              :close="!getStated('exe', data.item.value)"
              @click="data.select"
              @click:close="remove(data.item, 'executors')"
              class="chip-font"
            >
              <v-avatar left v-if="getStated('exe',  data.item.value)">
                <v-icon>{{icons.mdiAlertCircle}}</v-icon>
              </v-avatar>
              {{data.item.text}}
              <v-icon right v-if="getStated('exe',  data.item.value)" small @click="setStated('exe', data.item.value)">
                {{icons.mdiCheckboxMarkedCircle}}
              </v-icon>
            </v-chip>
          </template>
          <template v-slot:append-outer>
            <v-btn
              icon
              small
              color="primary"
              @click="showList($event, 'zones')"
            >
              <v-icon color="primary">{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-autocomplete
          v-model="project.relateds"
          multiple
          small-chips
          :items="selectZonesList"
          label="Vicepresidencia relacionada"
          hide-details
          :search-input.sync="inputSearch.relateds"
          @change="cleanSearch('relateds')"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              :color="!getStated('rel',  data.item.value) ? 'primary' : '#BDBDBD'"
              :close-icon="!getStated('rel',  data.item.value) ? icons.mdiCloseCircle : ''"
              :close="!getStated('rel',  data.item.value)"
              @click="data.select"
              @click:close="remove(data.item, 'relateds')"
              class="chip-font"
            >
              <v-avatar left v-if="getStated('rel',  data.item.value)">
                <v-icon>{{icons.mdiAlertCircle}}</v-icon>
              </v-avatar>
              {{data.item.text}}
              <v-icon right v-if="getStated('rel',  data.item.value)" small @click="setStated('rel', data.item.value)">
                {{icons.mdiCheckboxMarkedCircle}}
              </v-icon>
            </v-chip>
          </template>
          <template v-slot:append-outer>
            <v-btn
              icon
              small
              color="primary"
              @click="showList($event, 'zones')"
            >
              <v-icon color="primary">{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-autocomplete
          v-model="project.line_ids"
          multiple
          small-chips
          :items="selectLinesCategoriesList"
          label="Línea"
          hide-details
          item-value="value"
          item-text="title_category"
          :search-input.sync="inputSearch.line_ids"
          @change="cleanSearch('line_ids')"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              color="primary"
              close
              @click="data.select"
              @click:close="remove(data.item, 'line_ids')"
              class="chip-font"
            >
            {{data.item.title_category}}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item class="ml-4" >
                <template>
                  <v-list-item-action>
                    <v-checkbox :input-value="data.item.active" @click="data.item.active = !data.item.active" v-on="data.on"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.title_category"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </template>
          </template>
          <template v-slot:append-outer>
            <v-btn
              icon
              small
              color="primary"
              @click="showList($event, 'lines')"
            >
              <v-icon color="primary">{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-autocomplete
          v-model="project.sector_ids"
          multiple
          small-chips
          :items="selectSectorsList"
          label="Sector"
          hide-details
          :search-input.sync="inputSearch.sector_ids"
          @change="cleanSearch('sector_ids')"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              color="primary"
              close
              @click="data.select"
              @click:close="remove(data.item, 'sector_ids')"
              class="chip-font"
            >
            {{data.item.text}}
            </v-chip>
          </template>
          <template v-slot:append-outer>
            <v-btn
              icon
              small
              color="primary"
              @click="showList($event, 'sectors')"
            >
              <v-icon color="primary">{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-autocomplete
          v-model="project.subsector_ids"
          :items="selectSubSectorsList"
          label="Sub Sector"
          small-chips
          multiple
          hide-details
          :search-input.sync="inputSearch.subsector_ids"
          @change="cleanSearch('subsector_ids')"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              color="primary"
              close
              @click="data.select"
              @click:close="remove(data.item, 'subsector_ids')"
              class="chip-font"
            >
            {{data.item.text}}
            </v-chip>
          </template>
          <template v-slot:append-outer>
            <v-btn
              icon
              small
              color="primary"
              @click="showList($event, 'subsectors')"
            >
              <v-icon color="primary">{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-autocomplete
          v-model="project.geography_ids"
          :items="selectGeographiesList"
          label="Municipio / Departamento"
          hide-details
          multiple
          small-chips
          :search-input.sync="inputSearch.geography_ids"
          @change="cleanSearch('geography_ids')"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              color="primary"
              close
              @click="data.select"
              @click:close="remove(data.item, 'geography_ids')"
              class="chip-font"
            >
            {{data.item.text}}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <v-combobox
          v-model.lazy="mails"
          label="Correos de notificación"
          :items="selectEmails"
          :error-messages ="error"
          multiple
          hide-details="auto"
          hide-selected
          small-chips
          deletable-chips
          :search-input.sync="inputSearch.mails"
          @change="cleanSearch('mails')"
          @keyup.enter="addMails"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  No resultados para "<strong>{{ inputSearch.mails }}</strong>". Escriba el correo y presione <kbd>enter</kbd> para añadirlo
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-autocomplete
          v-model="project.cooperators"
          :items="selectThirdPartiesList"
          small-chips
          multiple
          label="Cooperantes"
          hide-details
          :search-input.sync="inputSearch.cooperators"
          @change="cleanSearch('cooperators')"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              :color="!getStated('coo',  data.item.value) ? 'primary' : '#BDBDBD'"
              :close="!getStated('coo',  data.item.value)"
              @click="data.select"
              :close-icon="!getStated('coo',  data.item.value) ? icons.mdiCloseCircle : ''"
              @click:close="remove(data.item, 'cooperators')"
              class="chip-font"
            >

              <v-avatar left v-if="getStated('coo',  data.item.value)">
                <v-icon>{{icons.mdiAlertCircle}}</v-icon>
              </v-avatar>
            {{data.item.text}}
            <v-icon right v-if="getStated('coo',  data.item.value)" small @click="setStated('coo', data.item.value)">
                {{icons.mdiCheckboxMarkedCircle}}
              </v-icon>
            </v-chip>
          </template>
          <template v-slot:append-outer>
            <v-btn
              icon
              small
              color="primary"
              @click="showList($event, 'third_parties')"
            >
              <v-icon color="primary">{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-autocomplete
          v-model="project.allies"
          :items="selectThirdPartiesList"
          chips
          small-chips
          multiple
          label="Aliados"
          hide-details
          :search-input.sync="inputSearch.allies"
          @change="cleanSearch('allies')"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              :color="!getStated('all',  data.item.value) ? 'primary' : '#BDBDBD'"
              :close-icon="!getStated('all',  data.item.value) ? icons.mdiCloseCircle : ''"
              :close="!getStated('all',  data.item.value)"
              @click="data.select"
              @click:close="remove(data.item, 'allies')"
              class="chip-font"
            >
              <v-avatar  left v-if="getStated('all',  data.item.value)">
                <v-icon>{{icons.mdiAlertCircle}}</v-icon>
              </v-avatar>
            {{data.item.text}}
              <v-icon right v-if="getStated('all',  data.item.value)" small @click="setStated('all', data.item.value)">
                {{icons.mdiCheckboxMarkedCircle}}
              </v-icon>
            </v-chip>
          </template>
          <template v-slot:append-outer>
            <v-btn
              icon
              small
              color="primary"
              @click="showList($event, 'third_parties')"
            >
              <v-icon color="primary">{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
        </v-autocomplete>
      </v-col>
      <v-divider></v-divider>
      </v-row>
  </v-form>
  <v-form
    ref="form2"
  >
    <v-row pa-0 ma-0>
      <v-col
        cols="12"
        md="6"
      >
        <v-btn
          color="rgba(211, 36, 43, 0.4)"
          class="mt-4 mb-3"
          @click="addBudget('btn')"
        >
        <v-icon>{{ icons.mdiPlus }}</v-icon>
        <span>Añadir Presupuesto</span>
        </v-btn>
        <v-row no-gutters class="justify-space-between">
          <v-col cols="4">
            <v-text-field
              label="Fuente Financiación"
              solo
              readonly
              class="input-filter"
            >
              <template v-slot:append-outer>
                <v-btn
                  icon
                  small
                  color="primary"
                  @click="showList($event, 'budget_source_types')"
                >
                  <v-icon color="primary">{{ icons.mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="5">
            <v-text-field
              label="Naturaleza"
              solo
              readonly
              class="input-filter"
            >
              <template v-slot:append-outer>
                <v-btn
                  icon
                  small
                  color="primary"
                  @click="showList($event, 'budget_types')"
                >
                  <v-icon color="primary">{{ icons.mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="5"
      >
        <v-card class="elevation-3">
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ totalBudgets | currency}}
              </h2>
              <span>Total Presupuesto</span>
            </div>
            <v-avatar
              color="success"
              :class="`v-avatar-light-bg success--text`"
            >
              <v-icon
                size="25"
                color="success"
                class="rounded-0"
              >
                {{ icons.mdiCashMultiple }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
      >
        <v-card flat>
          <v-data-table
            :headers="budgetColumns"
            :items="budgets"
            class="elevation-1"
          >

            <template #[`item.item`]="{item}">
              <div
                class="font-weight-medium text-decoration-none text-color"
              >#{{ item.item }}
              </div>
            </template>

            <template #[`item.origin`]="{item}">
              <v-combobox
                v-model="item.origin"
                :items="selectThirdPartiesList.map(item => item.text)"
                outlined
                class="pt-1 pb-1 input-table"
                hide-details="auto"
                :rules="[validators.required]"
                :readonly="readonly"
              />
            </template>

            <template #[`item.budget_source_type`]="{item}">
              <v-select
                v-model="item.budget_source_type_id"
                :items="selectBudgetSourceTypesList"
                outlined
                class="pt-1 pb-1 input-table"
                hide-details="auto"
                :rules="[validators.required]"
              />
            </template>

            <template #[`item.budget_type`]="{item}">
              <v-select
                v-model="item.budget_type_id"
                :items="selectBudgetTypesList"
                outlined
                class="pt-1 pb-1 input-table"
                hide-details="auto"
                :rules="[validators.required]"
              />
            </template>

            <template #[`item.budget`]="{item}">
              <v-currency-field
                v-model.number="item.budget"
                prefix="$"
                outlined
                hide-details="auto"
                class="input-table"
                :rules="[validators.required]"
              ></v-currency-field>
            </template>

            <template #[`item.stated_at`]="{item}">
              <v-chip
                small
                :color="resolveUserStatusVariant(item.stated_at)"
                :class="`${resolveUserStatusVariant(item.stated_at)}--text`"
                class="v-chip-light-bg font-weight-semibold text-capitalize"
              >
                {{ item.stated_at ? 'Activo' : 'Inactivo'}}
              </v-chip>
            </template>


            <template #[`item.actions`]="{item}">
              <div class="d-flex align-center justify-center">
                <!-- delete -->
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click=" item.stated_at ? deleteBudget(item) : ableBudget(item)"
                    >
                      <v-icon size="18">
                        {{ item.stated_at ? icons.mdiDeleteOutline : icons.mdiCheckCircleOutline}}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{item.stated_at ? 'Borrar' : 'Habilitar '}}</span>
                </v-tooltip>
                <!-- Añadir -->
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="addBudget"
                    >
                      <v-icon size="18">
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Añadir nuevo</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
  <v-form
    ref="form3"
  >
    <v-row pa-0 ma-0>
      <v-col
        cols="12"
      >
        <v-btn
          color="rgba(211, 36, 43, 0.4)"
          class="mt-4 mb-3"
          @click="addIndicator()"
        >
        <v-icon>{{ icons.mdiPlus }}</v-icon>
        <span>Nuevo Indicador</span>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
      >
        <v-card flat>
          <v-data-table
            :headers="newColumns"
            :items="indicators"
            class="elevation-1"
          >

            <template #[`item.item`]="{item}">
              <div
                class="font-weight-medium text-decoration-none text-color"
              >#{{ item.item }}
              </div>
            </template>

            <template #[`item.name_indicator`]="{item}">
              <v-text-field
                v-model="item.name_indicator"
                outlined
                class="pt-1 pb-1 input-table"
                hide-details="auto"
                :rules="[validators.required]"
              />
            </template>

            <template #[`item.goal`]="{item}">
              <v-text-field
                v-model="item.goal"
                outlined
                class="pt-1 pb-1 input-table"
                hide-details="auto"
                :rules="[validators.required]"
              />
            </template>

            <template #[`item.unit_measurement_id`]="{item}">
              <v-select
                v-model="item.unit_measurement_id"
                :items="selectUMList"
                outlined
                class="pt-1 pb-1 input-table"
                hide-details="auto"
                :rules="[validators.required]"
              />
            </template>


            <template #[`item.stated_at`]="{item}">
              <v-chip
                small
                :color="resolveUserStatusVariant(item.stated_at)"
                :class="`${resolveUserStatusVariant(item.stated_at)}--text`"
                class="v-chip-light-bg font-weight-semibold text-capitalize"
              >
                {{ item.stated_at ? 'Activo' : 'Inactivo'}}
              </v-chip>
            </template>

            <template #[`item.actions`]="{item}">
              <div class="d-flex align-center justify-center">
                <!-- delete -->
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click=" item.stated_at ? deleteIndicator(item) : ableIndicator(item)"
                    >
                      <v-icon size="18">
                        {{ item.stated_at ? icons.mdiDeleteOutline : icons.mdiCheckCircleOutline}}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{item.stated_at ? 'Borrar' : 'Habilitar '}}</span>
                </v-tooltip>
                <!-- Añadir -->
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="addIndicator"
                    >
                      <v-icon size="18">
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Añadir nuevo</span>
                </v-tooltip>
              </div>
            </template>

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
  <v-form
    ref="form4"
    class="pa-5"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-btn
          color="rgba(211, 36, 43, 0.4)"
          class="mt-4 mb-3"
          @click="addCost()"
          :disabled="readonly"
        >
        <v-icon>{{ icons.mdiPlus }}</v-icon>
        <span>Añadir Costo / Gasto</span>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="5"
      >
        <v-card class="elevation-3">
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ totalCosts | currency}}
              </h2>
              <span>Total Costo / Gasto</span>
            </div>
            <v-avatar
              color="success"
              :class="`v-avatar-light-bg success--text`"
            >
              <v-icon
                size="25"
                color="success"
                class="rounded-0"
              >
                {{ icons.mdiCashMultiple }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
      >
        <v-card flat>
          <v-data-table
            :headers="costColumns"
            :items="costs"
            class="elevation-1"
          >

            <template #[`item.item`]="{item}">
              <div
                class="font-weight-medium text-decoration-none text-color"
              >#{{ item.item }}
              </div>
            </template>

            <template #[`item.description`]="{item}">
              <v-text-field
                v-model="item.description"
                outlined
                class="pt-1 pb-1 input-table"
                hide-details="auto"
                :rules="[validators.required]"
                :readonly="readonly"
              ></v-text-field>
            </template>

            <template #[`item.value`]="{item}">
              <v-currency-field
                v-model.number="item.value"
                prefix="$"
                outlined
                class="input-table"
                hide-details="auto"
                :rules="[validators.required]"
                :readonly="readonly"
              ></v-currency-field>
            </template>

            <template #[`item.stated_at`]="{item}">
              <v-chip
                small
                :color="resolveUserStatusVariant(item.stated_at)"
                :class="`${resolveUserStatusVariant(item.stated_at)}--text`"
                class="v-chip-light-bg font-weight-semibold text-capitalize"
              >
                {{ item.stated_at ? 'Activo' : 'Inactivo'}}
              </v-chip>
            </template>

            <template #[`item.actions`]="{item}">
              <div class="d-flex align-center justify-center">
                <!-- delete -->
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      :disabled="readonly"
                      v-on="on"
                      @click=" item.stated_at ? deleteCost(item) : ableCost(item)"
                    >
                      <v-icon size="18">
                        {{ item.stated_at ? icons.mdiDeleteOutline : icons.mdiCheckCircleOutline}}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{item.stated_at ? 'Borrar' : 'Habilitar '}}</span>
                </v-tooltip>
                <!-- Añadir -->
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="addCost"
                      :disabled="readonly"
                    >
                      <v-icon size="18">
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Añadir nuevo</span>
                </v-tooltip>
              </div>
            </template>

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
  <v-row
    class="pa-5"
  >
    <v-spacer></v-spacer>
    <v-btn
      color="primary"
      @click="submit"
      :loading="loadingSubmit"
    >
      Guardar
    </v-btn>
    <v-btn
      @click="reset"
      outlined
      class="mx-2"
    >
      Limpiar
    </v-btn>
    <v-btn
      @click="close"
      color="secondary"
      class="mx-1"
    >
      Retornar
    </v-btn>
  </v-row>


    <dialog-item-select
      v-model="dialog"
      :options="options"
    >
    </dialog-item-select>

    <dialog-item-lines
      v-model="dialog2"
      :options="options"
    >
    </dialog-item-lines>

    <dialog-loading
      v-model="wait"
      v-if="wait"
    ></dialog-loading>

    <v-menu
      v-model="showMenu"
      bottom
      right
      :position-x="x"
      :position-y="y"
    >
      <v-list>
        <v-list-item
          @click="newEditItem('new')"
        >
          <v-list-item-title>
            <v-icon
              size="20"
              class="me-2"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>Nuevo Item</span>
          </v-list-item-title>
        </v-list-item>

        <v-list-item @click="newEditItem('edit')">
          <v-list-item-title>
            <v-icon
              size="20"
              class="me-2"
            >
              {{ icons.mdiPencilOutline }}
            </v-icon>
            <span>Editar Item</span>
          </v-list-item-title>
        </v-list-item>

        <v-list-item @click="newEditItem('delete')">
          <v-list-item-title>
            <v-icon
              size="20"
              class="me-2"
            >
              {{ icons.mdiDeleteOutline }}
            </v-icon>
            <span>Borrar Item</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Vue from 'vue'
import { avatarText } from '@core/utils/filter'
import { ref, reactive, computed, watch, provide, inject } from '@vue/composition-api'
import {
  mdiDotsVertical,
  mdiCalendar,
  mdiCashMultiple,
  mdiCloseCircle,
  mdiDeleteOutline,
  mdiAlertCircle,
  mdiCheckboxMarkedCircle,
  mdiPlus,
  mdiPencilOutline,
  mdiCheckCircleOutline,
} from '@mdi/js'
import { useRouter } from '@core/utils'
import VCurrencyField from '@/components/widgets/input/VCurrencyField.vue'
import DialogItemSelect from '@/components/widgets/dialog/DialogItemSelect.vue'
import DialogItemLines from '@/components/widgets/dialog/DialogItemLines.vue'
import DialogLoading from '@/components/widgets/dialog/DialogLoading.vue'
import moment from 'moment'
import { isNumber, required, emailValidator } from '@core/utils/validation'
import { validateForm } from '@/@core/utils'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    DialogItemSelect,
    DialogItemLines,
    DialogLoading,
    VCurrencyField,
    quillEditor,
  },
  setup() {
    const allies = ref([])
    const relateds = ref([])
    const executors = ref([])
    const allieBand = ref(false)
    const cooBand = ref(false)
    const cooperators = ref([])
    const resource_managers = ref([])
    const project = reactive({
      allies: [],
      code: '',
      cooperators: [],
      date_entry: '',
      description: '',
      duration: '',
      executors: [],
      geography_ids: [],
      line_ids: [],
      objective: '',
      origin_id: '',
      population_ids: [],
      project_type_id: '',
      relateds: [],
      resource_managers: [],
      sector_ids: [],
      specific_objectives: '',
      subsector_ids: [],
      unit_measure_duration: '',
      user_id: '',
      zone_ids: [],
    })
    let measuresDuration = [
      {
        text: 'Años',
        value: 'year',
      },
      {
        text: 'Meses',
        value: 'month',
      },
      {
        text: 'Dias',
        value: 'day',
      },
      {
        text: 'Horas',
        value: 'hour',
      },
    ]

    const inputSearch = reactive({
      executors: '',
      relateds: '',
      resource_managers: '',
      geography_ids: '',
      population_ids: '',
      line_ids: '',
      sector_ids: '',
      subsector_ids: '',
      cooperators: '',
      allies: '',
      mails: '',
    })
    const dialog = ref(false)
    const dialog2 = ref(false)
    const error = ref('')
    const loadingOne = ref(false)
    const loadingConfirm = ref(false)
    const loadingSubmit = ref(false)
    const showMenu = ref(false)
    const budgets = ref([])
    const costs = ref([])
    const indicators = ref([])
    const project_budgets = ref([])
    const project_costs = ref([])
    const project_indicators = ref([])
    const { router } = useRouter()
    const options = reactive({
      title: '',
      selectList: '',
      create: true,
      delete: false,
      model: '',
      keyName: '',
    })

    const menu = ref(false)
    const readonly = ref(false)
    const form = ref(null)
    const form2 = ref(null)
    const form3 = ref(null)
    const form4 = ref(null)
    const id = ref(0)
    const x = ref(null)
    const y = ref(null)

    const budgetColumns = inject('budgetColumns')
    const costColumns = inject('costColumns')
    const newColumns = [
      { text: '#ITEM', value: 'item', sortable: false },
      { text: 'NOMBRE', value: 'name_indicator', sortable: false },
      { text: 'META', value: 'goal', sortable: false },
      { text: 'UNIDAD DE MEDIDA', value: 'unit_measurement_id', sortable: false },
      { text: 'ESTADO', value: 'stated_at', sortable: false },
      {
        text: 'ACCIONES',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]
    const configModal = inject('configModal')
    const create = inject('create')
    const editorOption = inject('editorOption')
    const fetchOneFormulations = inject('fetchOneFormulations')
    const loading = inject('loading')
    const {
      selectEmails,
      selectBudgetSourceTypesList,
      selectBudgetTypesList,
      selectGeographiesList,
      selectLinesList,
      selectLinesCategoriesList,
      selectOriginsList,
      selectPopulationsList,
      selectSectorsList,
      selectStatusList,
      selectSubSectorsList,
      selectThirdPartiesList,
      selectTypeList,
      selectUMList,
      selectZonesList,
    } = inject('selects')
    const update = inject('update')
    const userList = inject('userList')
    const setOne = inject('setOne')
    const updateProject = inject('updateProjectFormulations')

    provide('createItem', create)
    provide('updateItem', update)
    provide('setOneItem', setOne)

    watch(budgets, val => {
      budgets.value.forEach((bud, i) => {
        bud.item = i + 1
      })
    })

    watch(costs, val => {
      costs.value.forEach((bud, i) => {
        bud.item = i + 1
      })
    })

    watch(indicators, val => {
      indicators.value.forEach((ind, i) => {
        ind.item = i + 1
      })
    })

    watch(
      () => project.allies,
      (val, prevVal) => {
        if (allieBand.value) {
          if (val.length > prevVal.length) {
            let finded = selectThirdPartiesList.value.find(sel => sel.value === val[val.length - 1])
            let full_name = finded.full_name
            addBudget(val[val.length - 1], full_name)
          } else {
            if (budgets.value.length === 0) return
            let deleted = budgets.value.find(coo => {
              return !project.allies.some(item => item === coo.id_allie)
            })
            if (!deleted['id_allie'] || !deleted) return
            let index = null
            budgets.value.forEach((bud, i) => {
              if (bud.id_allie === deleted.id_allie) {
                index = i
                return
              }
            })
            budgets.value.splice(index, 1)
          }
        }
      },
      {
        immediate: false,
      },
    )
    watch(
      () => project.cooperators,
      (val, prevVal) => {
        if (cooBand.value) {
          if (val.length > prevVal.length) {
            let finded = selectThirdPartiesList.value.find(sel => sel.value === val[val.length - 1])
            let full_name = finded.full_name
            addBudget(val[val.length - 1], full_name)
          } else {
            if (budgets.value.length === 0) return
            let deleted = budgets.value.find(coo => {
              return !project.cooperators.some(item => item === coo.id_allie)
            })
            if (!deleted['id_allie'] || !deleted) return
            let index = null
            budgets.value.forEach((bud, i) => {
              if (bud.id_allie === deleted.id_allie) {
                index = i
                return
              }
            })
            budgets.value.splice(index, 1)
          }
        }
      },
      {
        immediate: false,
      },
    )

    if (Object.keys(router.currentRoute.params).length !== 0) {
      loadingOne.value = true
      fetchOneFormulations(router.currentRoute.params.id)
        .then(res => {
          allies.value = res.project_formulation_allies
            .filter(allie => {
              return allie.type_third_party === 'stakeholder'
            })
            .map(item => {
              return {
                id: item.id,
                third_party_id: item.third_party_id,
                stated_at: item.stated_at,
              }
            })
          cooperators.value = res.project_formulation_allies
            .filter(coo => {
              return coo.type_third_party === 'cooperator'
            })
            .map(item => {
              return {
                id: item.id,
                third_party_id: item.third_party_id,
                stated_at: item.stated_at,
              }
            })
          executors.value = res.project_formulation_vicepresidencies
            .filter(executor => {
              return executor.type_vicepresidence === 'executor'
            })
            .map(item => {
              return {
                id: item.id,
                zone_id: item.zone_id,
                stated_at: item.stated_at,
              }
            })
          relateds.value = res.project_formulation_vicepresidencies
            .filter(rel => {
              return rel.type_vicepresidence === 'related'
            })
            .map(item => {
              return {
                id: item.id,
                zone_id: item.zone_id,
                stated_at: item.stated_at,
              }
            })
          resource_managers.value = res.project_formulation_resource_managers
          project.code = res.code
          project.date_entry = res.date_entry
          project.description = res.description
          project.duration = res.duration
          project.id = res.id
          project.objective = res.objective
          project.specific_objectives = res.specific_objectives
          project.origin_id = res.origin_id
          project.project_type_id = res.project_type_id
          project.user_id = res.user['id']
          project.geography_ids = res.geography_ids
          project.line_ids = res.line_ids
          project.line_ids.forEach(line => {
            selectLinesCategoriesList.value.find(item => item.value === line).active = true
          })
          project.population_ids = res.population_ids
          project.allies = allies.value.map(allie => allie.third_party_id)
          project.cooperators = cooperators.value.map(coo => coo.third_party_id)
          project.executors = executors.value.map(exe => exe.zone_id)
          project.relateds = relateds.value.map(rel => rel.zone_id)
          project.resource_managers = res.project_formulation_resource_managers
            .filter(item => item.stated_at)
            .map(man => man.user_id)
          project.sector_ids = res.sector_ids
          project.subsector_ids = res.subsector_ids
          project.unit_measure_duration = res.unit_measure_duration
          project.zone_ids = res.zone_ids
          project.scatter_list = res.scatter_list
          project_budgets.value = res.project_formulation_budgets.map(bud => {
            return {
              id: bud.id,
              origin: bud.type_budget,
              budget: Number(bud.budget),
              budget_source_type_id: bud.budget_source_type_id,
              budget_type_id: bud.budget_type_id,
              stated_at: bud.stated_at,
            }
          })
          project_costs.value = res.project_formulation_costs.map(bud => {
            return {
              id: bud.id,
              description: bud.name_of,
              value: Number(bud.executed_cost),
              stated_at: bud.stated_at,
            }
          })
          costs.value = project_costs.value.map(ele => ele)
          budgets.value = project_budgets.value.map(ele => ele)
          project_indicators.value = res.project_formulation_indicators.map(ind => {
            return {
              id: ind.id,
              name_indicator: ind.name_indicator,
              goal: ind.goal,
              unit_measurement_id: ind.unit_measurement_id,
              stated_at: ind.stated_at,
            }
          })
          indicators.value = project_indicators.value.map(ele => ele)
          loadingOne.value = false
        })
        .catch(() => {
          loadingOne.value = false
          loading.value = false
        })
    }

    const date_entry = computed({
      get: () => {
        if (project.date_entry === '') return ''
        return moment(project.date_entry).format('DD-MMM-YYYY')
      },
      set: value => {
        return value
      },
    })

    const wait = computed(() => {
      return loadingOne.value || loading.value
    })

    const getStated = (item, value) => {
      if (item === 'coo') {
        if (cooperators.value.find(cooperators => cooperators.third_party_id === value)) {
          return !cooperators.value.find(cooperators => cooperators.third_party_id === value).stated_at
        } else return false
      } else if (item === 'all') {
        if (allies.value.find(allies => allies.third_party_id === value)) {
          return !allies.value.find(allies => allies.third_party_id === value).stated_at
        } else return false
      } else if (item === 'rel') {
        if (relateds.value.find(rel => rel.zone_id === value)) {
          return !relateds.value.find(rel => rel.zone_id === value).stated_at
        } else return false
      } else if (item === 'exe') {
        if (executors.value.find(exe => exe.zone_id === value)) {
          return !executors.value.find(exe => exe.zone_id === value).stated_at
        } else return false
      } else return false
    }

    const setStated = (item, value) => {
      if (item === 'coo') {
        if (cooperators.value.find(cooperators => cooperators.third_party_id === value)) {
          let coo = cooperators.value.find(cooperators => cooperators.third_party_id === value)
          coo.stated_at = true
        } else return false
      } else if (item === 'all') {
        if (allies.value.find(allies => allies.third_party_id === value)) {
          let all = allies.value.find(allies => allies.third_party_id === value)
          all.stated_at = true
        } else return false
      } else if (item === 'rel') {
        if (relateds.value.find(rel => rel.zone_id === value)) {
          let rel = relateds.value.find(rel => rel.zone_id === value)
          rel.stated_at = true
        } else return false
      } else if (item === 'exe') {
        if (executors.value.find(exe => exe.zone_id === value)) {
          let exe = executors.value.find(exe => exe.zone_id === value)
          exe.stated_at = true
        } else return false
      }
    }

    const mails = computed({
      get: () => {
        if (project.scatter_list && project.scatter_list.length !== 0) {
          const emails = project.scatter_list.split(';')
          return emails
        } else return [] || ''
      },
      set: emails => {
        const valid = emailValidator(emails[emails.length - 1])
        if (valid !== true) {
          error.value = 'El campo tiene que ser un correo válido'
          return []
        }
        error.value = ''
        let text = ''
        if (emails.length === 0) {
          project.scatter_list = ''
          return
        } else {
          text = emails.join(';')
        }
        project.scatter_list = text
      },
    })

    const totalBudgets = computed(() => {
      if (!budgets.value.length) return 0
      let cont = 0
      budgets.value.forEach(ele => {
        if (ele.stated_at) cont = cont + ele.budget
      })
      return cont
    })

    const totalCosts = computed(() => {
      if (!costs.value.length) return 0
      let cont = 0
      costs.value.forEach(ele => {
        if (ele.stated_at) cont = cont + ele.value
      })
      return cont
    })

    const addBudget = (id, full_name) => {
      const isFormValid = validateForm(form2)
      if (!isFormValid && id === 'btn') return
      const newbudget = reactive({
        item: null,
        origin: id && full_name ? full_name : '',
        budget: '',
        budget_source_type_id: '',
        budget_type_id: '',
        stated_at: true,
      })
      if (id && full_name) {
        newbudget.id_allie = id
      }
      budgets.value.push(newbudget)
    }

    const addCost = () => {
      const isFormValid = validateForm(form3)
      if (!isFormValid) return
      const newCost = reactive({
        item: null,
        description: '',
        value: '',
        stated_at: true,
      })
      costs.value.push(newCost)
    }

    const addMails = e => {
      mails.value.push(e.target.value)
    }

    const addIndicator = () => {
      const isFormValid = validateForm(form3)
      if (!isFormValid) return
      const newIndex = reactive({
        item: null,
        name_indicator: '',
        goal: '',
        unit_measurement_id: '',
        stated_at: true,
      })
      indicators.value.push(newIndex)
    }

    const newEditItem = key => {
      if (key === 'new') {
        options.create = true
        options.delete = false
      } else {
        options.create = false
        options.delete = false
      }
      if (key === 'delete') {
        options.delete = true
        options.create = false
      }
      if (options.selectList2) {
        dialog2.value = true
      } else dialog.value = true
    }

    const showList = (e, key) => {
      e.preventDefault()
      x.value = e.clientX
      y.value = e.clientY
      Vue.nextTick(() => {
        showMenu.value = true
        options.title = configModal(key).title
        options.selectList = configModal(key).select_list
        if (key === 'lines') {
          options.selectList2 = configModal(key).select_list2
          options.model2 = configModal(key).model2
          options.keyName2 = configModal(key).keyName2
        } else {
          options.selectList2 = null
          options.model2 = null
          options.keyName2 = null
        }
        options.model = configModal(key).model
        options.keyName = configModal(key).keyName
      })
    }

    const close = () => {
      reset()
      router.push('/proyectos/lista')
    }

    const resolveUserStatusVariant = status => {
      if (status) return 'success'
      else return 'error'
    }

    const ableBudget = item => {
      item.stated_at = true
    }

    const ableCost = item => {
      item.stated_at = true
    }

    const ableIndicator = item => {
      item.stated_at = true
    }

    const cleanSearch = key => {
      inputSearch[key] = ''
      if (key == 'allies') allieBand.value = true
      if (key == 'cooperators') cooBand.value = true
    }

    const deleteBudget = item => {
      let index = budgets.value.indexOf(item)
      budgets.value.splice(index, 1)
    }

    const deleteCost = item => {
      let index = costs.value.indexOf(item)
      costs.value.splice(index, 1)
    }

    const deleteIndicator = item => {
      let index = indicators.value.indexOf(item)
      indicators.value.splice(index, 1)
    }

    const onEditorChange = e => {}

    const reset = () => {
      form.value.reset()
      budgets.value = []
      indicators.value = []
      costs.value = []
    }

    const remove = (item, key) => {
      if (key === 'line_ids') item.active = false
      const index = project[key].indexOf(item.value)
      if (index >= 0) project[key].splice(index, 1)
    }

    const submit = async () => {
      try {
        const isFormValid = validateForm(form)
        const isFormValid2 = validateForm(form2)
        const isFormValid3 = validateForm(form3)
        const isFormValid4 = validateForm(form4)
        if (!isFormValid || !isFormValid2 || !isFormValid3 || !isFormValid4) return

        loadingSubmit.value = true
        let project_formulation_allies_attributes = []
        let project_formulation_vicepresidencies_attributes = []

        if (project.allies) {
          project.allies.forEach(allie => {
            let ele = {}
            let exist = allies.value.find(ele => ele.third_party_id === allie)
            if (exist) {
              ele = {
                id: exist.id,
                third_party_id: allie,
                type_third_party: 'stakeholder',
                stated_at: exist.stated_at,
              }
            } else {
              ele = {
                third_party_id: allie,
                type_third_party: 'stakeholder',
                stated_at: true,
              }
            }
            project_formulation_allies_attributes.push(ele)
          })
        }

        if (project.cooperators) {
          project.cooperators.forEach(coo => {
            let ele = {}
            let exist = cooperators.value.find(ele => ele.third_party_id === coo)
            if (exist) {
              ele = {
                id: exist.id,
                third_party_id: coo,
                type_third_party: 'cooperator',
                stated_at: exist.stated_at,
              }
            } else {
              ele = {
                third_party_id: coo,
                type_third_party: 'cooperator',
                stated_at: true,
              }
            }
            project_formulation_allies_attributes.push(ele)
          })
        }

        if (project.executors) {
          project.executors.forEach(executor => {
            let ele = {}
            let exist = executors.value.find(exe => exe.zone_id === executor)
            if (exist) {
              ele = {
                id: exist.id,
                zone_id: executor,
                type_vicepresidence: 'executor',
                stated_at: exist.stated_at,
              }
            } else {
              ele = {
                zone_id: executor,
                type_vicepresidence: 'executor',
                stated_at: true,
              }
            }
            project_formulation_vicepresidencies_attributes.push(ele)
          })
        }

        if (project.relateds) {
          project.relateds.forEach(related => {
            let ele = {}
            let exist = relateds.value.find(rel => rel.zone_id === related)
            if (exist) {
              ele = {
                id: exist.id,
                zone_id: related,
                type_vicepresidence: 'related',
                stated_at: exist.stated_at,
              }
            } else {
              ele = {
                zone_id: related,
                type_vicepresidence: 'related',
                stated_at: true,
              }
            }
            project_formulation_vicepresidencies_attributes.push(ele)
          })
        }

        let project_costs_attributes = []
        if (costs.value) {
          costs.value.forEach(cost => {
            const ele = {
              name_of: cost.description,
              executed_cost: cost.value,
              stated_at: cost.stated_at,
            }
            if (cost.id) ele.id = cost.id
            project_costs_attributes.push(ele)
          })
        }

        if (Object.keys(router.currentRoute.params).length !== 0) {
          let deletedExe = executors.value.filter(exe => {
            return !project.executors.some(item => item === exe.zone_id)
          })
          let deletedRel = relateds.value.filter(rel => {
            return !project.allies.some(item => item === rel.zone_id)
          })
          let deletedCost = project_costs.value.filter(bud => {
            return !costs.value.some(item => item.id === bud.id)
          })

          if (deletedCost.length > 0) {
            deletedCost.forEach(del => {
              let ele = {
                id: del.id,
                name_of: del.description,
                executed_cost: del.value,
                stated_at: false,
              }
              project_costs_attributes.push(ele)
            })
          }

          if (deletedExe.length > 0) {
            deletedExe.forEach(del => {
              let ele = {
                id: del.id,
                type_vicepresidence: 'executor',
                zone_id: del.zone_id,
                stated_at: false,
              }
              project_formulation_vicepresidencies_attributes.push(ele)
            })
          }
          if (deletedRel.length > 0) {
            deletedRel.forEach(del => {
              let ele = {
                id: del.id,
                type_vicepresidence: 'related',
                zone_id: del.zone_id,
                stated_at: false,
              }
              project_formulation_vicepresidencies_attributes.push(ele)
            })
          }
        }

        if (Object.keys(router.currentRoute.params).length !== 0) {
          let deletedCoo = cooperators.value.filter(coo => {
            return !project.cooperators.some(item => item === coo.third_party_id)
          })
          let deletedAllie = allies.value.filter(all => {
            return !project.allies.some(item => item === all.third_party_id)
          })

          if (deletedCoo.length > 0) {
            deletedCoo.forEach(del => {
              let ele = {
                id: del.id,
                type_third_party: 'cooperator',
                third_party_id: del.third_party_id,
                stated_at: false,
              }
              project_formulation_allies_attributes.push(ele)
            })
          }
          if (deletedAllie.length > 0) {
            deletedAllie.forEach(del => {
              let ele = {
                id: del.id,
                type_third_party: 'stakeholder',
                third_party_id: del.third_party_id,
                stated_at: false,
              }
              project_formulation_allies_attributes.push(ele)
            })
          }
        }

        project['project_formulation_costs_attributes'] = project_costs_attributes
        project['project_formulation_allies_attributes'] = project_formulation_allies_attributes
        project['project_formulation_vicepresidencies_attributes'] = project_formulation_vicepresidencies_attributes

        let project_formulation_resource_managers_attributes = []
        if (project.resource_managers.length > 0) {
          project.resource_managers.forEach(re => {
            let ele = {}
            let exist = resource_managers.value.find(ele => ele.user_id === re)
            if (exist) {
              ele = {
                id: exist.id,
                user_id: re,
                stated_at: true,
              }
            } else {
              ele = {
                user_id: re,
                stated_at: true,
              }
            }
            project_formulation_resource_managers_attributes.push(ele)
          })
        }

        let project_formulation_budgets_attributes = []
        if (budgets.value) {
          budgets.value.forEach(budget => {
            const ele = {
              type_budget: budget.origin,
              budget: budget.budget,
              budget_type_id: budget.budget_type_id,
              budget_source_type_id: budget.budget_source_type_id,
              stated_at: budget.stated_at,
            }
            if (budget.id) ele.id = budget.id
            project_formulation_budgets_attributes.push(ele)
          })
        }
        let project_formulation_indicators_attributes = []
        if (indicators.value) {
          indicators.value.forEach(ind => {
            const ele = {
              name_indicator: ind.name_indicator,
              goal: ind.goal,
              unit_measurement_id: ind.unit_measurement_id,
              stated_at: ind.stated_at,
            }
            if (ind.id) ele.id = ind.id
            project_formulation_indicators_attributes.push(ele)
          })
        }
        if (Object.keys(router.currentRoute.params).length !== 0) {
          let deletedBud = project_budgets.value.filter(bud => {
            return !budgets.value.some(item => item.id === bud.id)
          })
          let deletedRe = resource_managers.value.filter(re => {
            return !project.resource_managers.some(item => item === re.user_id)
          })
          let deletedInd = project_indicators.value.filter(re => {
            return !indicators.value.some(item => item.id === re.id)
          })
          if (deletedBud.length > 0) {
            deletedBud.forEach(del => {
              let ele = {
                id: del.id,
                type_budget: del.origin,
                budget: Number(del.budget),
                budget_type_id: del.budget_type_id,
                budget_source_type_id: del.budget_source_type_id,
                stated_at: false,
              }
              project_formulation_budgets_attributes.push(ele)
            })
          }
          if (deletedRe.length > 0) {
            deletedRe.forEach(del => {
              let ele = {
                id: del.id,
                user_id: del.user_id,
                stated_at: false,
              }
              project_formulation_resource_managers_attributes.push(ele)
            })
          }
          if (deletedInd.length > 0) {
            deletedInd.forEach(del => {
              let ele = {
                id: del.id,
                name_indicator: del.name_indicator,
                goal: del.goal,
                unit_measurement_id: del.unit_measurement_id,
                stated_at: false,
              }
              project_formulation_indicators_attributes.push(ele)
            })
          }
        }

        project['project_formulation_budgets_attributes'] = project_formulation_budgets_attributes
        project['project_formulation_indicators_attributes'] = project_formulation_indicators_attributes
        project['project_formulation_resource_managers_attributes'] = project_formulation_resource_managers_attributes

        delete project.allies
        delete project.cooperators
        delete project.resource_managers
        delete project.relateds
        delete project.executors

        await updateProject(project)
        loadingSubmit.value = false
        close()
      } catch (error) {
        console.log(error)
        loadingSubmit.value = false
      }
    }

    return {
      addMails,
      addIndicator,
      ableBudget,
      ableCost,
      ableIndicator,
      addBudget,
      addCost,
      allieBand,
      allies,
      avatarText,
      budgetColumns,
      costColumns,
      budgets,
      costs,
      cooBand,
      cooperators,
      cleanSearch,
      close,
      configModal,
      date_entry,
      deleteBudget,
      deleteCost,
      deleteIndicator,
      dialog,
      dialog2,
      editorOption,
      selectEmails,
      error,
      form,
      form2,
      form3,
      form4,
      getStated,
      setStated,
      id,
      indicators,
      inputSearch,
      loading,
      loadingOne,
      loadingConfirm,
      loadingSubmit,
      mails,
      measuresDuration,
      menu,
      newColumns,
      newEditItem,
      onEditorChange,
      options,
      project,
      readonly,
      remove,
      reset,
      resolveUserStatusVariant,
      selectBudgetSourceTypesList,
      selectBudgetTypesList,
      selectGeographiesList,
      selectLinesList,
      selectLinesCategoriesList,
      selectOriginsList,
      selectStatusList,
      selectPopulationsList,
      selectSectorsList,
      selectSubSectorsList,
      selectThirdPartiesList,
      selectTypeList,
      selectUMList,
      selectZonesList,
      showMenu,
      showList,
      submit,
      totalBudgets,
      totalCosts,
      userList,
      wait,
      x,
      y,
      icons: {
        mdiAlertCircle,
        mdiDotsVertical,
        mdiCalendar,
        mdiCashMultiple,
        mdiCheckboxMarkedCircle,
        mdiCheckCircleOutline,
        mdiCloseCircle,
        mdiPencilOutline,
        mdiPlus,
        mdiDeleteOutline,
      },
      validators: {
        emailValidator,
        isNumber,
        required,
      },
    }
  },
}
</script>

<style scoped>
.text-color {
  color: #d3242b !important;
}
</style>
